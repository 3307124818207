import Ajax from "./ajax";

const ModuleApi = {};

ModuleApi.list = (params) => {
    let url = "/module/list"
    return Ajax.json(url, params)
}

ModuleApi.delete = (params) => {
    let url = "/module/delete"
    return Ajax.json(url, params)
}

ModuleApi.detail = (params) => {
    let url = "/module/detail"
    return Ajax.json(url, params)
}

ModuleApi.update = (params) => {
    let url = "/module/update"
    return Ajax.json(url, params)
}

ModuleApi.create = (params) => {
    let url = "/module/add"
    return Ajax.json(url, params)
}

export default ModuleApi