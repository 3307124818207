import React, {useLayoutEffect, useState} from 'react';
import {Outlet, Link, useNavigate} from "react-router-dom";
import {MenuFoldOutlined, MenuUnfoldOutlined, UserOutlined,LogoutOutlined} from '@ant-design/icons';
import { Layout, Menu, Button, Space,Avatar,Dropdown } from 'antd';
import {SecurityApi} from '@api/index'

import "./basicLayout.less"


export default function BasicLayout(props) {

    const navigate = useNavigate();
    const [collapsed, setCollapsed] = useState(false);
    const [menus, setMenus] = useState([])
    const [userName,setUserName] = useState()


    useLayoutEffect(() => {
        getMenu()
        getUserName()
    }, [])

    const getMenu = async () => {
        let ret = await SecurityApi.userMenus()
        if (ret && ret.code === 200) {
           setMenus(ret.data || [])
        }
    }

    const getUserName=() => {
        let info =  sessionStorage.getItem("user_inf")
        let infoObj = JSON.parse(info)
        setUserName(infoObj["userName"])
    }

    const menu = (
        <Menu>
            <Menu.Item icon={<LogoutOutlined/>}>
                <Link to="/">退出登录</Link>
            </Menu.Item>
        </Menu>
    );

    const changeMenu=(item)=>{
        let props=item.item.props
        if(props.url){
            navigate(props.url)
        }
    }

    const { Header, Sider, Content } = Layout;

    return (
        <Layout style={{minHeight: '100vh'}}>
            <Sider trigger={null} collapsible collapsed={collapsed} >
                <div className="demo-logo-vertical" />
                <Menu
                    theme="dark"
                    mode="inline"
                    defaultSelectedKeys={['1']}
                    items={menus}
                    onClick={changeMenu}
                />
            </Sider>
            <Layout>
                <Header className="header">
                    <Button
                        type="text"
                        icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                        onClick={() => setCollapsed(!collapsed)}
                        style={{
                            fontSize: '16px',
                            width: 64,
                            height: 64,
                        }}
                    />
                    <div className="user-center">
                        <Space size={'large'}>
                            <Dropdown overlay={menu} placement="bottomLeft">
                                <Avatar icon={<UserOutlined/>} alt={userName}/>
                            </Dropdown>
                        </Space>
                    </div>
                </Header>
                <Content
                    style={{
                        margin: '24px 16px',
                        padding: 24,
                        minHeight: 280,
                    }}
                >
                    <Outlet/>
                </Content>
            </Layout>
        </Layout>
    );
}