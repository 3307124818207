import Ajax from "./ajax";

const FtpUserApi = {};

FtpUserApi.list = (params) => {
    let url = "/ftp/user/list"
    return Ajax.json(url, params)
}

FtpUserApi.create = (params) => {
    let url = "/ftp/user/create"
    return Ajax.json(url, params)
}

FtpUserApi.delete = (params) => {
    let url = "/ftp/user/delete"
    return Ajax.json(url, params)
}

FtpUserApi.update = (params) => {
    let url = "/ftp/user/update"
    return Ajax.json(url, params)
}

FtpUserApi.detail = (params) => {
    let url = "/ftp/user/detail"
    return Ajax.json(url, params)
}

export default FtpUserApi