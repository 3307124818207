import React, {useState,useLayoutEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {Flex, Button, Table, message, Space, Popconfirm, Modal} from 'antd';
import {UserApi} from '@api/index'

import Add from './components/add'

import './list.less'

export default (props) => {

    let navigate = useNavigate();
    const [list,setList]=useState([])
    const [addModal,setAddModal]=useState(false)
    const [updateModal,setUpdateModal]=useState(false)
    const [currItem,setCurrItem]=useState(false)
    const [total,setTotal]=useState(0)
    const [pageSize,setPageSize]=useState(20)
    const [page,setPage]=useState(0)

    useLayoutEffect(() => {
        getProductList()
    }, [page,pageSize])

    const getProductList= async ()=> {
        let params = {
            page:page,
            pageSize:pageSize,
        }
        let ret = await UserApi.list(params);
        if (ret && ret.code === 200) {
            setList(ret.data.content || [])
            setTotal(ret.data.totalElements)
        }
    }

    const showAddModal=()=> {
        setAddModal(true)
    }

    const closeAddModal=()=> {
        setAddModal(false)
        getProductList()
    }

    const closeUpdateModal=()=> {
        setUpdateModal(false)
        getProductList()
    }

    const resetPWD= async (id)=>{
        let ret = await UserApi.reset({id:id});
        if (ret && ret.code === 200) {
            const modal = Modal.info();
            modal.update({
                title: '用户密码',
                content: ret.data,
            });
        } else {
            message.error(ret.message);
        }
    }

    const columns = [
        {
            title: '名称',
            dataIndex: 'nickname',
            key: 'nickname',
        },
        {
            title: '账号',
            dataIndex: 'userName',
            key: 'userName',
        },
        {
            title: '手机号码',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: '联系地址',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: '操作',
            key: 'action',
            width:220,
            render: (_, record) => (
                <Space size="middle">
                    <Popconfirm
                        onConfirm={()=>{resetPWD(record.id)}}
                        title="确认重置密码?"
                    >
                        <a>重置密码</a>
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    const pagination = {
        onChange: (page, pageSize) => {
            setPageSize(pageSize)
            setPage(page-1)
        },
        pageSize:pageSize,
        total: total
    }

    return (
        <div>
            <Flex gap="small" justify="flex-end">
                <Button type="primary" onClick={showAddModal}>创建客户</Button>
            </Flex>
            <Table columns={columns} dataSource={list} pagination={pagination}/>
            <Add modalVisible={addModal} onClose={closeAddModal}></Add>
        </div>
    );
};