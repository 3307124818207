import React, {useState,useLayoutEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {Modal, Space, Table, message} from 'antd';
import {allExpanded, defaultStyles, JsonView} from "react-json-view-lite";
import {CertApi} from '@api/index'

import Add from './components/add'
import Update from './components/update'

import setting from "@/setting";

import './list.less'
import 'react-json-view-lite/dist/index.css';


export default (props) => {

    const navigate = useNavigate();
    const [list,setList]=useState([])
    const [addModal,setAddModal]=useState(false)
    const [updateModal,setUpdateModal]=useState(false)
    const [currItem,setCurrItem]=useState(false)
    const [viewModal,setViewModal]=useState(false)
    const [json,setJson]=useState(false)
    const [total,setTotal]=useState(0)
    const [pageSize,setPageSize]=useState(20)
    const [page,setPage]=useState(0)

    useLayoutEffect(() => {
        getCertList()
    }, [page,pageSize])

    const getCertList= async ()=> {
        let params = {
            page:page,
            pageSize:pageSize,
        }
        let ret = await CertApi.list(params);
        if (ret && ret.code === 200) {
            setList(ret.data.content || [])
            setTotal(ret.data.totalElements)
        }
    }

    const showAddModal=()=> {
        setAddModal(true)
    }

    const deleteCert = async (id)=> {
        let ret = await CertApi.delete({"id":id});
        if (ret && ret.code === 200) {
            message.success("操作成功")
            getCertList()
        }
    }

    const showUpdateCert = () =>{
        setUpdateModal(true)
    }

    const closeAddModal=()=> {
        setAddModal(false)
        getCertList()
    }

    const closeUpdateModal=()=> {
        setUpdateModal(false)
        getCertList()
    }

    const handleOk =()=> {
        setViewModal(false)
    }

    const handleCancel=() => {
        setViewModal(false)
    }

    const validate= async (id)=>{
        let ret = await CertApi.validate({"id":id})
        setJson(ret)
        setViewModal(true)
    }

    const columns = [
        {
            title: '客户名称',
            dataIndex: 'customName',
            key: 'customName',
        },
        {
            title: '设备序列号',
            dataIndex: 'devSerialnumber',
            key: 'devSerialnumber',
        },
        {
            title: '授权类型',
            dataIndex: 'authType',
            key: 'authType',
            render: (text, record) => (
                <>{text=='1'?"正式授权":"测试授权"}</>
            ),
        },
        {
            title: '创建时间',
            dataIndex: 'authCreatetime',
            key: 'authCreatetime',
        },
        {
            title: '截止时间',
            dataIndex: 'authEndtime',
            key: 'authEndtime',
        },
        {
            title: '授权范围',
            dataIndex: 'authRange',
            key: 'authRange',
            render: (text, record) => (
                <>{text=='1'?"全授权":"非全授权"}</>
            ),
        },
        {
            title: '节点数',
            dataIndex: 'authPoints',
            key: 'authPoints',
        },
        {
            title: '产品名称',
            dataIndex: 'productName',
            key: 'productName',
        },
        {
            title: '操作',
            key: 'action',
            width:220,
            render: (_, record) => (
                <Space size="middle">
                    <a href={setting.url+ "/certificate/download?id="+record.id + "&X-ACCESS-TOKEN="+sessionStorage.getItem("user_token")}>下载</a>
                    <a onClick={()=>{validate(record.id)}}>验证</a>
                </Space>
            ),
        },
    ];

    const pagination = {
        onChange: (page, pageSize) => {
            setPageSize(pageSize)
            setPage(page-1)
        },
        pageSize:pageSize,
        total: total
    }

    return (
        <div className="cert-container">
            <Table columns={columns} dataSource={list} pagination={pagination}/>
            <Add modalVisible={addModal} onClose={closeAddModal}></Add>
            <Update modalVisible={updateModal} id={currItem} onClose={closeUpdateModal}></Update>
            <Modal title="验证" open={viewModal} onOk={handleOk} onCancel={handleCancel} width={800} style={{maxHeight:600}} >
                <JsonView data={json} shouldExpandNode={allExpanded} style={{maxHeight:600,...defaultStyles}}  />
            </Modal>
        </div>
    );
};