import React from 'react';
import {Form, Input, Modal, message} from 'antd';
import {FtpUserApi} from '@api/index'

export default class Update extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            modalVisible: false,
            id:null,
            ftpUser:{},
        };
        this.formRef = React.createRef();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.modalVisible !== this.state.modalVisible) {
            this.setState({
                modalVisible: nextProps.modalVisible,
                id: nextProps.id
            },()=>{
                this.detail()
            });
        }
    }

    detail = async ()=>{
        let ret = await FtpUserApi.detail({id:this.state.id});
        if (ret && ret.code === 200) {
            this.setState({
                ftpUser: ret.data
            })
        }
    }

    handleOk = async e => {
        let data = await this.formRef.current.validateFields();
        data.id=this.state.id
        let ret = await FtpUserApi.update(data);
        if (ret && ret.code === 200) {
            message.success('操作成功！');
            this.onClose()
            this.setState({
                modalVisible: false,
            });
        } else {
            message.error(ret.message);
        }
    };

    handleCancel = async e => {
        this.onClose()
        this.setState({
            modalVisible: false,
        });
    };

    onClose(){
        this.props.onClose && this.props.onClose()
    }

    render() {

        const {modalVisible} =this.state

        const HasContent=(p)=>{
            if (modalVisible){
                return <>{p.children}</>
            }
            return null;
        }

        return (
            <HasContent>
            <Modal
                title={"修改FTP用户"}
                open={this.state.modalVisible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                destroyOnClose={true}
            >
                <Form ref={this.formRef} wrapperCol={{span: 14}} labelCol={{span: 6}} initialValues={this.state.ftpUser}>
                    <Form.Item name="userid" label="账号" rules={[{required: true, message: "请输入名称"}]}>
                        <Input maxLength={20} allowClear autoComplete={"off"}/>
                    </Form.Item>
                    <Form.Item name="userpassword" label="密码" rules={[{required: true, message: "请输入密码"}]}>
                        <Input.Password maxLength={20} allowClear autoComplete={"off"}/>
                    </Form.Item>
                    <Form.Item name="homedirectory" label="主目录" rules={[{required: true, message: "请输入主目录路径"}]}>
                        <Input maxLength={20} allowClear autoComplete={"off"}/>
                    </Form.Item>
                </Form>
            </Modal>
            </HasContent>
        );
    }
}
