import React, {useState,useLayoutEffect} from 'react';
import {useNavigate,useParams} from 'react-router-dom';
import {Flex, Button, Space, Table, message} from 'antd';
import {ModuleApi} from '@api/index'

import Add from './components/add'
import Update from './components/update'


import './list.less'

export default (props) => {

    let navigate = useNavigate();

    const [list,setList]=useState([])
    const [addModal,setAddModal]=useState(false)
    const [updateModal,setUpdateModal]=useState(false)
    const [currItem,setCurrItem]=useState(false)
    const parameters = useParams()

    const [total,setTotal]=useState(0)
    const [pageSize,setPageSize]=useState(20)
    const [page,setPage]=useState(0)


    useLayoutEffect(() => {
        getModuleList()
    }, [page,pageSize])

    const getModuleList= async ()=> {
        let params = {
            ...parameters,
            page:page,
            pageSize:pageSize,
        }
        let ret = await ModuleApi.list(params);
        if (ret && ret.code === 200) {
            setList(ret.data.content || [])
            setTotal(ret.data.totalElements)
        }
    }

    const showAddModal=()=> {
        setAddModal(true)
    }

    const deleteModule = async (id)=> {
        let ret = await ModuleApi.delete({"id":id});
        if (ret && ret.code === 200) {
            message.success("操作成功")
            getModuleList()
        }
    }

    const showUpdateModule = (id) =>{
        setUpdateModal(true)
        setCurrItem(id)
    }

    const closeAddModal=()=> {
        setAddModal(false)
        getModuleList()
    }

    const closeUpdateModal=()=> {
        setUpdateModal(false)
        getModuleList()
    }


    const columns = [
        {
            title: '一级模块',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '编号',
            dataIndex: 'identifier',
            key: 'identifier',
        },
        {
            title: '二级模块',
            dataIndex: 'moduleSecond',
            key: 'moduleSecond',
        },
        {
            title: '说明',
            dataIndex: 'introduction',
            key: 'introduction',
        },
        {
            title: '是否启用',
            dataIndex: 'permission',
            key: 'permission',
            render: (text, record) => (
                <>{text==1?"是":"否"}</>
            ),
        },
        {
            title: '操作',
            key: 'action',
            width:220,
            render: (_, record) => (
                <Space size="middle">
                    <a onClick={()=>{showUpdateModule(record.id)}}>编辑</a>
                    <a onClick={()=>{deleteModule(record.id)}}>删除</a>
                </Space>
            ),
        },
    ];

    const pagination = {
        onChange: (page, pageSize) => {
            setPageSize(pageSize)
            setPage(page-1)
        },
        pageSize:pageSize,
        total: total
    }

    const getback =()=>{
        window.history.back()
    }

    return (
        <div>
            <Flex gap="small" justify="flex-end">
                <Button type="primary" onClick={showAddModal}>添加模块</Button>
                <Button type="primary" onClick={getback}>返回</Button>
            </Flex>
            <Table columns={columns} dataSource={list} pagination={pagination} />
            <Add modalVisible={addModal} productId={parameters.productId} onClose={closeAddModal}></Add>
            <Update modalVisible={updateModal} productId={parameters.productId} id={currItem} onClose={closeUpdateModal}></Update>
        </div>
    );
};