import React, {useState,useLayoutEffect} from 'react';
import {Table} from 'antd';
import {DownloadApi} from '@api/index'


import './list.less'

export default (props) => {

    const [list,setList]=useState([])
    const [total,setTotal]=useState(0)
    const [pageSize,setPageSize]=useState(20)
    const [page,setPage]=useState(0)

    useLayoutEffect(() => {
        getDownloadList()
    }, [page,pageSize])

    const getDownloadList= async ()=> {
        let params = {
            page:page,
            pageSize:pageSize,
        }
        let ret = await DownloadApi.list(params);
        if (ret && ret.code === 200) {
            setList(ret.data.content || [])
            setTotal(ret.data.totalElements)
        }
    }

    const columns = [
        {
            title: '用户名称',
            dataIndex: 'userName',
            key: 'userName',
        },
        {
            title: '文件名称',
            dataIndex: 'fileName',
            key: 'fileName',
        },
        {
            title: '开始时间',
            dataIndex: 'startTime',
            key: 'startTime',
        },
        {
            title: '结束时间',
            dataIndex: 'endTime',
            key: 'endTime',
        },
        {
            title: '状态',
            dataIndex: 'completed',
            key: 'completed',
            render: (text, record) => (
                <>{text==1?"成功":"失败"}</>
            ),
        },

    ];

    const pagination = {
        onChange: (page, pageSize) => {
            setPageSize(pageSize)
            setPage(page-1)
        },
        pageSize:pageSize,
        total: total
    }

    return (
        <div>
            <Table columns={columns} dataSource={list} pagination={pagination}/>
        </div>
    );
};