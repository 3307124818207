import React, {useState,useLayoutEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {Flex, Button, Space, Table, Select, message,Popconfirm} from 'antd';
import {PKGApi,ProductApi} from '@api/index'

import HasRole from '@components/security/index'

import Add from './components/add'
import Update from './components/update'
import setting from "@/setting";

import './list.less'

export default (props) => {

    let navigate = useNavigate();
    const [list,setList]=useState([])
    const [addModal,setAddModal]=useState(false)
    const [updateModal,setUpdateModal]=useState(false)
    const [currItem,setCurrItem]=useState(false)
    const [productList,setProductList]=useState([])
    const [versionList,setVersionList]=useState([])
    const [productId,setProductId]=useState()
    const [version,setVersion]=useState()

    const [total,setTotal]=useState(0)
    const [pageSize,setPageSize]=useState(20)
    const [page,setPage]=useState(0)

    useLayoutEffect(() => {
        getPKGList()
        getProductList()
    }, [version,productId,page,pageSize,updateModal])

    const getPKGList= async ()=> {
        let params = {type:2}
        params["productId"]=productId
        params["page"] = page
        params["pageSize"]=pageSize
        let ret = await PKGApi.list(params);
        if (ret && ret.code === 200) {
            setList(ret.data.content || [])
            setTotal(ret.data.totalElements)
        }
    }

    const getProductList=async () =>{
        let ret = await ProductApi.all({type:2});
        if (ret && ret.code === 200) {
            let arr=ret.data
            let ops = arr.map(((item)=>({ label: item["name"], value: item["id"] })))
            ops.unshift({ label:"请选择组件", value: null})
            setProductList(ops)
        }
    }

    const showAddModal=()=> {
        setAddModal(true)
    }

    const deletePKG = async (id)=> {
        let ret = await PKGApi.delete({"id":id});
        if (ret && ret.code === 200) {
            message.success("操作成功")
            getPKGList()
        }
    }

    const showUpdatePKG = (id) =>{
        setUpdateModal(true)
        setCurrItem(id)
    }

    const closeAddModal=()=> {
        setAddModal(false)
        getPKGList()
    }

    const closeUpdateModal=()=> {
        setUpdateModal(false)
        getPKGList()
    }

    const downloadPKG=  async (id)=>{
        let ret = await PKGApi.download({"id":id})
        if (ret && ret.code === 200) {
            window.location.href=setting.url + "/pkg/download?id=" + id + "&X-ACCESS-TOKEN="+sessionStorage.getItem("user_token")
        }else{
            message.error("文件不存在")
        }
    }

    const columns = [
        {
            title: '名称',
            dataIndex: 'productName',
            key: 'productName',
        },
        {
            title: '文件名称',
            dataIndex: 'fullName',
            key: 'fullName',
        },
        {
            title: '版本',
            dataIndex: 'version',
            key: 'version',
        },
        {
            title: '主程序版本',
            dataIndex: 'supportedVersions',
            key: 'supportedVersions',
            width: 300,
            ellipsis: true,
        },
        {
            title: '开放更新',
            dataIndex: 'updatable',
            key: 'updatable',
            width: 120,
            ellipsis: true,
            render: (text, record) => (<>{text==1?"是":"否"}</>),
        },
        {
            title: '版本说明',
            dataIndex: 'releaseNote',
            key: 'releaseNote',
        },
        {
            title: '操作',
            key: 'action',
            width:220,
            render: (_, record) => (
                <Space size="middle">
                    <HasRole role="admin">
                        <Popconfirm
                            onConfirm={() => {
                                deletePKG(record.id)
                            }}
                            title="确认删除?"
                        >
                            <a>删除</a>
                        </Popconfirm>
                    </HasRole>
                    <HasRole role="admin">
                        <a onClick={() => showUpdatePKG(record.id)}>编辑</a>
                    </HasRole>
                        <a onClick={() => downloadPKG(record.id)}>下载</a>
                    {/*<a href={setting.url + "/pkg/download?id=" + record.id + "&X-ACCESS-TOKEN="+sessionStorage.getItem("user_token")}>下载</a>*/}
                </Space>
            ),
        },
    ];

    const changeProduct = (value)=>{
        setProductId(value)
    }

    const pagination = {
        onChange: (page, pageSize) => {
            setPageSize(pageSize)
            setPage(page-1)
        },
        pageSize:pageSize,
        total: total
    }

    return (
        <div>
            <Flex flex={1} gap="small" justify="space-between" style={{marginBottom:"12px"}}>
                <Flex gap="small" justify="flex-start">
                    <Select
                        defaultValue={null}
                        style={{ width: 160 }}
                        onChange={changeProduct}
                        options={productList}
                    />
                </Flex>
                <Flex gap="small" justify="flex-end">
                    <HasRole role="admin">
                        <Button type="primary" onClick={showAddModal}>添加软件包</Button>
                    </HasRole>
                </Flex>
            </Flex>
            <Table columns={columns} dataSource={list} pagination={pagination} />
            <Add modalVisible={addModal} onClose={closeAddModal}></Add>
            <Update updateModal={updateModal} setUpdateModal={setUpdateModal} id={currItem} ></Update>
        </div>
    );
};