import React, {useState,useLayoutEffect} from 'react';
import {Flex, Button, Space, Table, message,Popconfirm} from 'antd';
import {FtpUserApi} from '@api/index'
import Add from './components/add'

import './list.less'

export default (props) => {

    const [list,setList]=useState([])
    const [addModal,setAddModal]=useState(false)
    const [updateModal,setUpdateModal]=useState(false)
    const [currItem,setCurrItem]=useState(false)
    const [current,setCurrent]=useState(0)

    const [total,setTotal]=useState(0)
    const [pageSize,setPageSize]=useState(20)
    const [page,setPage]=useState(0)

    useLayoutEffect(() => {
        getFtpList()
    }, [page,pageSize])

    const getFtpList= async ()=> {
        let params = {
            page:page,
            pageSize:pageSize,
        }
        let ret = await FtpUserApi.list(params);
        if (ret && ret.code === 200) {
            setList(ret.data.content || [])
            setTotal(ret.data.totalElements)
        }
    }

    const deleteUser = async (id)=> {
        let ret = await FtpUserApi.delete({"id":id});
        if (ret && ret.code === 200) {
            message.success("操作成功")
            getFtpList()
        }
    }

    const showAddModal=()=> {
        setAddModal(true)
    }

    const showUpdateModal=(userId)=> {
        setCurrItem(userId)
        setUpdateModal(true)
    }

    const closeAddModal=()=> {
        setAddModal(false)
        getFtpList()
    }

    const closeUpdateModal=()=> {
        setUpdateModal(false)
        getFtpList()
    }

    const columns = [
        {
            title: '账号',
            dataIndex: 'userid',
            key: 'userid',
        },
        {
            title: '主目录',
            dataIndex: 'homedirectory',
            key: 'homedirectory',
        },
        {
            title: '是否可用',
            dataIndex: 'enableflag',
            key: 'enableflag',
            render: (text, record) => (
                <>{text==1?"是":"否"}</>
            ),
        },
        {
            title: '写权限',
            dataIndex: 'writepermission',
            key: 'writepermission',
            render: (text, record) => (
                <>{text==1?"是":"否"}</>
            ),
        },
        {
            title: '操作',
            key: 'action',
            width:220,
            render: (_, record) => (
                <Space size="middle">
                    <Popconfirm
                        onConfirm={()=>{deleteUser(record.id)}}
                        title="确认删除?"
                    >
                    <a>删除</a>
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    const pagination = {
        onChange: (page, pageSize) => {
            setPageSize(pageSize)
            setPage(page-1)
        },
        pageSize:pageSize,
        total: total
    }

    return (
        <div>
            <Flex gap="small" justify="flex-end">
                <Button type="primary" onClick={showAddModal}>添加FTP用户</Button>
            </Flex>
            <Table columns={columns} dataSource={list} pagination={pagination}/>
            <Add modalVisible={addModal} onClose={closeAddModal}></Add>
        </div>
    );
};