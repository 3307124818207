import React, {useEffect,useState} from 'react';
import {Form, Input, Modal, message, Select, Switch} from 'antd';
import {PKGApi,UserApi} from '@api/index'

export default ({id,updateModal,setUpdateModal,handler}) => {
    const [form] = Form.useForm()
    const[userList,setUserList]=useState([])

    useEffect(() => {
        if(updateModal&&id) {
            detail()
            getUserList()
        }
    }, [id,updateModal]);

    const detail = async () => {
        if (id) {
            let ret = await PKGApi.detail({id: id});
            if (ret && ret.code === 200) {
                form.setFieldsValue(ret.data)
            }
        }
    }

    const getUserList=async () => {
        let ret = await UserApi.listAll();
        if (ret && ret.code === 200) {
            let arr=ret.data.map((item,index)=>{
                return {
                    label:item.nickname,
                    value:item.id
                }
            })
            setUserList(arr || [])
        }
    }

    const handleOk = async e => {
        let data = await form.validateFields();
        let ret = await PKGApi.update({id:id,type: 1, ...data});
        if (ret && ret.code === 200) {
            message.success('操作成功！');
            handler()
            setUpdateModal(false)
        } else {
            message.error(ret.message);
        }
    };

    const handleCancel = async e => {
        setUpdateModal(false)
    };


    return (
        <Modal
            title={"发布新版本"}
            open={updateModal}
            onOk={handleOk}
            onCancel={handleCancel}
            destroyOnClose={true}
        >
            <Form form={form} wrapperCol={{span: 14}} labelCol={{span: 6}} autoComplete={"off"}>
                <Form.Item name="productName" label="项目" rules={[{required: true}]}>
                    <Input maxLength={20} disabled/>
                </Form.Item>
                <Form.Item name="version" label="版本" rules={[{required: true}]}>
                    <Input maxLength={20} disabled/>
                </Form.Item>
                <Form.Item name="fullName" label="全称" rules={[{required: true}]}>
                    <Input maxLength={100} disabled/>
                </Form.Item>
                <Form.Item name="updatable" label="开放更新" >
                    <Switch/>
                </Form.Item>
                <Form.Item name="grayscale" label="灰度发布">
                    <Switch/>
                </Form.Item>
                <Form.Item  noStyle shouldUpdate={(pre,cur)=>pre.grayscale!==cur.grayscale}>
                    {({getFieldValue})=>{
                        const gray = getFieldValue('grayscale');
                        return gray?    <Form.Item name="grayscaleUsers" label="灰度用户"><Select
                            mode="multiple"
                            options={userList}
                        /></Form.Item>:null
                    }}
                </Form.Item>
            </Form>
        </Modal>
    );
}
