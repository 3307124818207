import Ajax from "./ajax";

const CertApi = {};

CertApi.list = (params) => {
    let url = "/certificate/list"
    return Ajax.json(url, params)
}

CertApi.all = (params) => {
    let url = "/certificate/list/all"
    return Ajax.json(url, params)
}

CertApi.delete = (params) => {
    let url = "/certificate/delete"
    return Ajax.json(url, params)
}

CertApi.create = (params) => {
    let url = "/certificate/add"
    return Ajax.json(url, params)
}

CertApi.validate = (params) => {
    let url = "/certificate/check"
    return Ajax.json(url, params)
}

export default CertApi