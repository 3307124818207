import React, {useState,useLayoutEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {Flex, Button, Space, Table, message,Popconfirm} from 'antd';
import {ProductApi} from '@api/index'

import Add from './components/add'
import Update from './components/update'


import './list.less'

export default (props) => {

    let navigate = useNavigate();
    const [list,setList]=useState([])
    const [addModal,setAddModal]=useState(false)
    const [updateModal,setUpdateModal]=useState(false)
    const [currItem,setCurrItem]=useState(false)


    useLayoutEffect(() => {
        getProductList()
    }, [])

    const getProductList= async ()=> {
        let params = {
            type:1
        }
        let ret = await ProductApi.list(params);
        if (ret && ret.code === 200) {
            setList(ret.data.content || [])
        }
    }

    const showAddModal=()=> {
        setAddModal(true)
    }

    const deleteProduct = async (id)=> {
        let ret = await ProductApi.delete({"id":id});
        if (ret && ret.code === 200) {
            message.success("操作成功")
            getProductList()
        }else{
            message.error(ret.message)
        }
    }

    const showUpdateProduct = () =>{
        console.log('haha')
        setUpdateModal(true)
    }

    const closeAddModal=()=> {
        setAddModal(false)
        getProductList()
    }

    const closeUpdateModal=()=> {
        setUpdateModal(false)
        getProductList()
    }

    const toModule=(id)=>{
        navigate("/module/list/"+id)
    }

    const toAuthorize=(id) =>{
        navigate("/product/authorize/"+id)
    }


    const columns = [
        {
            title: '名称',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '编号',
            dataIndex: 'identifier',
            key: 'identifier',
        },
        {
            title: '介绍',
            dataIndex: 'introduction',
            key: 'introduction',
        },
        {
            title: '操作',
            key: 'action',
            width:220,
            render: (_, record) => (
                <Space size="middle">
                    <a onClick={()=>{toModule(record.id)}}>编辑模块</a>
                    <a onClick={()=>{toAuthorize(record.id)}}>制作授权</a>
                    <Popconfirm
                        onConfirm={()=>{deleteProduct(record.id)}}
                        title="确认删除?"
                    >
                        <a>删除</a>
                    </Popconfirm>
                </Space>
            ),
        },
    ];


    return (
        <div>
            <Flex gap="small" justify="flex-end">
                <Button type="primary" onClick={showAddModal}>添加产品</Button>
            </Flex>
            <Table columns={columns} dataSource={list} />
            <Add modalVisible={addModal} onClose={closeAddModal}></Add>
            <Update modalVisible={updateModal} id={currItem} onClose={closeUpdateModal}></Update>
        </div>
    );
};