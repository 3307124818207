import React from 'react';
import {Form, Input, Modal, message, Select} from 'antd';
import {FtpUserApi} from '@api/index'
import {UserApi} from '@api/index'

export default class Add extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            modalVisible: false,
            userList: [],
        };
        this.formRef = React.createRef();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.modalVisible !== this.state.modalVisible) {
            this.setState({
                modalVisible: nextProps.modalVisible,
            });
            this.getUserList()
        }
    }

    handleOk = async e => {
        let data = await this.formRef.current.validateFields();
        let ret = await FtpUserApi.create(data);
        if (ret && ret.code === 200) {
            message.success('操作成功！');
            this.onClose()
            this.setState({
                modalVisible: false,
            });
        } else {
            message.error(ret.message);
        }
    };

    handleCancel = async e => {
        this.onClose()
        this.setState({
            modalVisible: false,
        });
    };

    onClose(){
        this.props.onClose && this.props.onClose()
    }

    getUserList=async () =>{
        let ret = await UserApi.listAll();
        if (ret && ret.code === 200) {
            let arr=ret.data
            let ops = arr.map(((item)=>({ label: item.userName, value: item.userName })))
            this.setState({
                userList:ops
            })
        }
    }
    render() {

        return (
            <Modal
                title={"增加FTP用户"}
                open={this.state.modalVisible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                destroyOnClose={true}
            >
                <Form ref={this.formRef} wrapperCol={{span: 14}} labelCol={{span: 6}}>
                    <Form.Item name="userid" label="账号" rules={[{required: true, message: "请输入名称"}]}>
                        <Input maxLength={20} allowClear autoComplete={"off"}/>
                    </Form.Item>
                    <Form.Item name="userpassword" label="密码" rules={[{required: true, message: "请输入密码"}]}>
                        <Input.Password maxLength={20} allowClear autoComplete={"off"}/>
                    </Form.Item>
                    <Form.Item name="sysUserId" label="系统用户"  rules={[{required: true, message: "请选择用户"}]}>
                        <Select style={{ width: '100%' }} options={this.state.userList}/>
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}
