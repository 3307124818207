import {Row, Col, Form, Input, InputNumber, Table, Button, Select, DatePicker, message, Flex} from "antd";
import React, {useLayoutEffect, useState} from "react";
import {ModuleApi,CertApi,UserApi} from '@api/index'
import {useParams} from "react-router-dom";

export default (props) => {

    const parameters = useParams()
    const [list,setList]=useState([])
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const[userList,setuserList]=useState([])
    const[loading,setLoading]=useState(false)

    useLayoutEffect(() => {
        getModuleList()
        getUserList()
    }, [])

    const getModuleList= async ()=> {
        let params = {
            ...parameters,
            pageSize:1000
        }
        let ret = await ModuleApi.list(params);
        if (ret && ret.code === 200) {
            setList(ret.data.content || [])
        }
    }
    const getUserList=async () => {
        let ret = await UserApi.listAll();
        if (ret && ret.code === 200) {
            let arr=ret.data.map((item,index)=>{
                return {
                    label:item.nickname,
                    value:item.id
                }
            })
            setuserList(arr || [])
        }
    }

    const columns = [
        {
            title: '名称',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '编号',
            dataIndex: 'identifier',
            key: 'identifier',
        },
        {
            title: '说明',
            dataIndex: 'introduction',
            key: 'introduction',
        },
    ];
    
    const onFinish =async (values) => {
        setLoading(true)
        let ret = await CertApi.create({
            moduelIds: selectedRowKeys.toString(),
            ... parameters,
            ...values
        });
        setTimeout(function (){
            setLoading(false)
        },1000)
        setLoading(false)
        if (ret && ret.code === 200) {
            message.success("操作成功")
        }else {
            message.error(ret.msg)
        }
    }

    const choose = (newSelectedRowKeys) =>{
        setSelectedRowKeys(newSelectedRowKeys)
    }

    const getback =()=>{
        window.history.back()
    }

    return (
        <div>
            <Flex gap="small" justify="flex-end">
                <Button type="primary" onClick={getback}>返回</Button>
            </Flex>
            <Form  wrapperCol={{span: 14}} labelCol={{span: 6}} autoComplete={"off"} onFinish={onFinish} initialValues={{authPoints: 0}}>
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item name="authType" label="授权类型" rules={[{required: true}]}>
                            <Select style={{ width: '100%' }} options={[{ value: '0', label: '测试授权'},{ value: '1', label: '正式授权'}]}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="authRange" label="授权范围" rules={[{required: true}]}>
                            <Select style={{ width: '100%' }} options={[{ value: '0', label: '非全授权'},{ value: '1', label: '全授权'}]}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="authPoints" label="授权点数" rules={[{required: true}]}>
                            <InputNumber style={{ width: '100%' }}  min={1} allowClear/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="applicant" label="申请人" rules={[{required: true}]}>
                            <Input maxLength={20} allowClear autoComplete={"off"}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="userId" label="客户" rules={[{required: true}]}>
                            <Select
                                options={userList}
                            />
                        </Form.Item>
                    </Col>
                    {/*<Col span={12}>*/}
                    {/*    <Form.Item name="address" label="客户地址" rules={[{required: true}]}>*/}
                    {/*        <Input maxLength={40} allowClear autoComplete={"off"}/>*/}
                    {/*    </Form.Item>*/}
                    {/*</Col>*/}
                    <Col span={12}>
                        <Form.Item name="devSerialnumber" label="设备序列号" rules={[{required: true}]}>
                            <Input maxLength={100} allowClear/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="authEndtime" label="授权截止时间" rules={[{required: true}]}>
                            <DatePicker style={{ width: '100%' }} format="YYYY-MM-DD" allowClear/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="edrEndTime" label="EDR授权截止时间">
                            <DatePicker style={{ width: '100%' }} allowClear/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="ndrEndTime" label="NDR授权截止时间">
                            <DatePicker style={{ width: '100%' }} allowClear/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="soarEndTime" label="SOAR授权截止时间">
                            <DatePicker style={{ width: '100%' }} allowClear/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="warroomEndTime" label="WarRoom授权截止时间">
                            <DatePicker style={{ width: '100%' }} format="YYYY-MM-DD" allowClear/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="xdrEndTime" label="XDR授权截止时间">
                            <DatePicker style={{ width: '100%' }} allowClear/>
                        </Form.Item>
                    </Col>
                </Row>
                <Table rowSelection={{onChange:choose}} columns={columns} dataSource={list} rowKey={"id"}  scroll={{x:0,y:550}} pagination={false}/>
                <Button type="primary" loading={loading} htmlType="submit">提交</Button>
            </Form>
        </div>
    );
}