import React from 'react';
import {Form, Input, Modal, message,Select,Switch } from 'antd';
import {UserApi} from '@api/index'

export default class Add extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            modalVisible: false,
            options:[]
        };
        this.formRef = React.createRef();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.modalVisible !== this.state.modalVisible) {
            this.setState({
                modalVisible: nextProps.modalVisible,
            });
        }
    }

    handleOk = async e => {
        let data = await this.formRef.current.validateFields();
        let ret = await UserApi.create(data);
        if (ret && ret.code === 200) {

            const modal = Modal.info();
            modal.update({
                title: '用户密码',
                content: ret.data,
            });
            
            this.onClose()
            this.setState({
                modalVisible: false,
            });
        } else {
            message.error(ret.message);
        }
    };

    handleCancel = async e => {
        this.onClose()
        this.setState({
            modalVisible: false,
        });
    };

    onClose(){
        this.props.onClose && this.props.onClose()
    }

    render() {

        return (
            <Modal
                title={"创建客户"}
                open={this.state.modalVisible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                destroyOnClose={true}
            >
                <Form ref={this.formRef} wrapperCol={{span: 14}} labelCol={{span: 6}} autoComplete={"off"}>
                    <Form.Item name="nickname" label="客户名称" rules={[{required: true}]}>
                        <Input maxLength={40} allowClear/>
                    </Form.Item>
                    <Form.Item name="userName" label="账号" rules={[{required: true}]}>
                        <Input maxLength={40} allowClear/>
                    </Form.Item>
                    <Form.Item name="phone" label="手机号码" rules={[{required: true}]}>
                        <Input maxLength={20} allowClear />
                    </Form.Item>
                    <Form.Item name="address" label="联系地址" rules={[{required: true}]}>
                        <Input.TextArea maxLength={200} allowClear/>
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}
