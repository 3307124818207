import React from 'react';
import {Form, Input, Modal, message,Select,Switch } from 'antd';
import {PKGApi,ProductApi,UserApi} from '@api/index'

export default class Add extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            modalVisible: false,
            options:[],
            versionList:[],
            productId:null,
            hidden:true,
            userList:[],
        };
        this.formRef = React.createRef();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.modalVisible !== this.state.modalVisible) {
            this.setState({
                modalVisible: nextProps.modalVisible,
            });
            this.getProductList()
            this.getVersionList()
            this.getUserList()
        }
    }

    handleOk = async e => {
        let data = await this.formRef.current.validateFields();
        let ret = await PKGApi.create({type:1,...data});
        if (ret && ret.code === 200) {
            message.success('操作成功！');
            this.onClose()
            this.setState({
                modalVisible: false,
            });
        } else {
            message.error(ret.message);
        }
    };

    getUserList=async () => {
        let ret = await UserApi.listAll();
        if (ret && ret.code === 200) {
            let arr=ret.data.map((item,index)=>{
                return {
                    label:item.nickname,
                    value:item.id
                }
            })
            this.setState({userList:arr})
        }
    }

    handleCancel = async e => {
        this.onClose()
        this.setState({
            modalVisible: false,
        });
    };

    onClose(){
        this.props.onClose && this.props.onClose()
    }

    getProductList=async () =>{
        let ret = await ProductApi.all({type:1});
        if (ret && ret.code === 200) {
            let arr=ret.data
            let ops = arr.map(((item)=>({ label: item["name"], value: item["id"] })))
            this.setState({
                options:ops
            })
        }
    }

    getVersionList=async () =>{
        let ret = await PKGApi.versions({type:1,productId:this.state.productId});
        if (ret && ret.code === 200) {
            let arr=ret.data
            let ops = arr.map(((item)=>({ label: item.version, value: item.id })))
            this.setState({
                versionList:ops
            })
        }
    }

    changeVersion = (values) =>{
        this.setState({
            ids:values
        })
    }

    changeProduct=(value) =>{
        this.setState({
            productId:value
        },()=>{
            this.getVersionList()
        })
    }

    changeGrayscale=(checked)=>{
        this.setState({
            hidden:!checked
        })
    }

    render() {
        return (
            <Modal
                title={"发布新版本"}
                open={this.state.modalVisible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                destroyOnClose={true}
            >
                <Form ref={this.formRef} wrapperCol={{span: 14}} labelCol={{span: 6}} >
                    <Form.Item name="productId" label="项目" rules={[{required: true}]}>
                        <Select style={{ width: '100%' }} options={this.state.options} onChange={this.changeProduct}/>
                    </Form.Item>
                    <Form.Item name="version" label="版本" rules={[{required: true}]}>
                        <Input maxLength={20} allowClear autoComplete={"off"}/>
                    </Form.Item>
                    <Form.Item name="fullName" label="文件名称" rules={[{required: true}]}>
                        <Input maxLength={100} allowClear autoComplete={"off"}/>
                    </Form.Item>
                    <Form.Item name="supportedIds" label="支持版本">
                        <Select
                            mode="multiple"
                            options={this.state.versionList}
                        />
                    </Form.Item>
                    <Form.Item name="releaseNote" label="版本说明" rules={[{required: true}]}>
                        <Input.TextArea maxLength={100} allowClear autoComplete={"off"}/>
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}
