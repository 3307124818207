export default function HasRole(props) {
    let info =  sessionStorage.getItem("user_inf")
    let obj= JSON.parse(info)
    if(obj && obj.role) {
        let roleArr=obj.role.split(",")
        if (roleArr.includes(props.role)) {
            return <>{props.children}</>
        }
    }
    return <></>
}