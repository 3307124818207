import React from 'react';
import {useNavigate} from "react-router-dom";
import {Form, Input, Button, Checkbox, message} from 'antd';
import {UserOutlined, LockOutlined} from '@ant-design/icons';
import {UserApi} from '@api/index'

import './login.less'

export default (props) => {

    let navigate = useNavigate();

    const onFinish = async (values) => {
        let ret = await UserApi.login(values)
        if (ret && ret.code === 200) {
            let token = ret.data
            let payload =token.split(".")[1]
            let info =decodeURIComponent(atob(payload))
            sessionStorage.setItem("user_token", token)
            sessionStorage.setItem("user_inf",info)
            console.log(info)
            navigate('/product/list')
        } else {
            message.error(ret.message);
        }
    };

    return (
        <div className='login-container'>
            <div className="wraper">
                <div className='login-form-wraper'>
                    <div>
                        <h1 style={{color: "#fff"}}>未来智安</h1>
                    </div>
                    <Form name="normal_login" className="login-form" initialValues={{remember: true}} onFinish={onFinish}>
                        <Form.Item name="userName" rules={[{required: true, message: '请输入账号',},]}>
                            <Input size="large" prefix={<UserOutlined/>} placeholder="账号"/>
                        </Form.Item>
                        <Form.Item name="password" rules={[{required: true, message: '请输入密码',},]}>
                            <Input.Password size="large" prefix={<LockOutlined/>} type="password" placeholder="密码"/>
                        </Form.Item>
                        <Form.Item>
                            <Form.Item name="remember" valuePropName="checked" noStyle>
                                <Checkbox>记住我</Checkbox>
                            </Form.Item>
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" className="login-form-button">
                                登录
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </div>
    );
};
