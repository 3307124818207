import Ajax from "./ajax";

const UserApi = {};

UserApi.login = (params) => {
    let url = "/login"
    return Ajax.json(url, params)
}

UserApi.list = (params) => {
    let url = "/user/list"
    return Ajax.json(url, params)
}

UserApi.create = (params) => {
    let url = "/user/add"
    return Ajax.json(url, params)
}

UserApi.reset = (params) => {
    let url = "/user/reset"
    return Ajax.json(url, params)
}

UserApi.listAll = () => {
    let url = "/user/list/all"
    return Ajax.json(url)
}

export default UserApi