import React from 'react';
import {Form, Input, Modal, message,Select,Switch } from 'antd';
import {ModuleApi} from '@api/index'

export default class Add extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            modalVisible: false,
            options:[],
            productId:null,
            permission:0
        };
        this.formRef = React.createRef();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.modalVisible !== this.state.modalVisible) {
            this.setState({
                modalVisible: nextProps.modalVisible,
                productId:nextProps.productId
            });
        }
    }

    handleOk = async e => {
        let data = await this.formRef.current.validateFields();
        data["productId"]=this.state.productId
        data["permission"]=this.state.permission
        let ret = await ModuleApi.create(data);
        if (ret && ret.code === 200) {
            message.success('操作成功！');
            this.onClose()
            this.setState({
                modalVisible: false,
            });
        } else {
            message.error(ret.message);
        }
    };

    handleCancel = async e => {
        this.onClose()
        this.setState({
            modalVisible: false,
        });
    };

    onClose(){
        this.props.onClose && this.props.onClose()
    }

    changePermission=(checked)=>{
        if(checked){
            this.setState({permission:1})
        }else{
            this.setState({permission:0})
        }
    }

    render() {

        return (
            <Modal
                title={"添加模块"}
                open={this.state.modalVisible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                destroyOnClose={true}
            >
                <Form ref={this.formRef} wrapperCol={{span: 14}} labelCol={{span: 6}} autoComplete={"off"}>
                    <Form.Item name="name" label="一级模块" rules={[{required: true}]}>
                        <Input maxLength={100} allowClear autoComplete={"off"}/>
                    </Form.Item>
                    <Form.Item name="identifier" label="编号" rules={[{required: true}]}>
                        <Input maxLength={100} allowClear/>
                    </Form.Item>
                    <Form.Item name="moduleSecond" label="二级模块" rules={[{required: true}]}>
                        <Input maxLength={100} allowClear/>
                    </Form.Item>
                    <Form.Item name="introduction" label="路径" rules={[{required: true}]}>
                        <Input maxLength={100} allowClear/>
                    </Form.Item>
                    <Form.Item name="permission" label="是否启用">
                        <Switch onChange={this.changePermission}/>
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}
