import axios from 'axios'
import setting from "@/setting";

const Ajax = {}

const axiosInstance = axios.create({
    baseURL: setting.url,
    timeout: 5000
})

/**
 * 请求拦截添加 token
 */
axiosInstance.interceptors.request.use(function (config) {
    config.headers['X-ACCESS-TOKEN'] = sessionStorage.getItem("user_token") || ''
    return config;
}, function (error) {
    return Promise.reject(error);
});


axiosInstance.interceptors.response.use(resp=>{
    if(resp && resp.status===200)
        return resp.data
},(error='') => {
    if(error && error.response) {
        let httpStatus = error.response.status
        if(httpStatus===401) {
            window.location.href="/"
        }
    }

    return Promise.reject(error);
})

Ajax.json = async (url, params) => {
    return await axiosInstance({
        method: 'post',
        url: url,
        data: params || {},
        header:{
            'Content-Type':'application/json',
        }
    });
}

Ajax.post = async (url, params) => {
    return await axiosInstance({
        method: 'post',
        url: url,
        params: params || {},
        header:{
            'Content-Type': 'application/x-www-form-urlencoded',
        }
    });
}

export default Ajax
