import Ajax from "./ajax";

const PKGApi = {};

PKGApi.list = (params) => {
    let url = "/pkg/list"
    return Ajax.json(url, params)
}

PKGApi.delete = (params) => {
    let url = "/pkg/delete"
    return Ajax.json(url, params)
}

PKGApi.create = (params) => {
    let url = "/pkg/add"
    return Ajax.json(url, params)
}

PKGApi.update = (params) => {
    let url = "/pkg/update"
    return Ajax.json(url, params)
}

PKGApi.detail = (params) => {
    let url = "/pkg/detail"
    return Ajax.json(url, params)
}

PKGApi.versions= (params) =>{
    let url = "/pkg/versions"
    return Ajax.json(url, params)
}

PKGApi.download=  async (params) =>{
    let url = "/pkg/download/check"
    return Ajax.json(url, params)
}

export default PKGApi