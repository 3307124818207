import Ajax from "./ajax";

const ProductApi = {};

ProductApi.list = (params) => {
    let url = "/product/list"
    return Ajax.json(url, params)
}

ProductApi.all = (params) => {
    let url = "/product/list/all"
    return Ajax.json(url, params)
}

ProductApi.delete = (params) => {
    let url = "/product/delete"
    return Ajax.json(url, params)
}

ProductApi.create = (params) => {
    let url = "/product/add"
    return Ajax.json(url, params)
}

export default ProductApi