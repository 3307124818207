import {BrowserRouter, Routes, Route,} from "react-router-dom";

import BasicLayout from "@/layouts/basicLayout";
import PkgList from '@/pages/pkg/list'
import UnitPkgList from '@/pages/unitpkg/list'
import FtpUserList from '@/pages/ftpUser/list'
import ProductList from '@/pages/product/list'
import UnitList from '@/pages/unit/list'
import Authorize from '@/pages/product/authorize'
import CertList from '@/pages/cert/list'
import UserList from '@/pages/user/list'
import ModuleList from '@/pages/module/list'
import Login from '@/pages/login/login'
import DownloadRecord from '@/pages/download/list'
import UserPKGList from '@/pages/userPKGList/list'

import './App.css';
function App() {
  return (
      <BrowserRouter>
        <Routes>
            <Route key={1} path={'/'} element={<Login/>}></Route>
            <Route path={'/'} element={<BasicLayout/>}>
                <Route path={'/pkg/list'} element={<PkgList/>}></Route>
                <Route path={'/unit/pkg/list'} element={<UnitPkgList/>}></Route>
                <Route path={'/ftp/user/list'} element={<FtpUserList/>}></Route>
                <Route path={'/product/list/'} element={<ProductList/>}></Route>
                <Route path={'/unit/list/'} element={<UnitList/>}></Route>
                <Route path={'/product/authorize/:productId'} element={<Authorize/>}></Route>
                <Route path={'/cert/list'} element={<CertList/>}></Route>
                <Route path={'/user/list'} element={<UserList/>}></Route>
                <Route path={'/module/list/:productId'} element={<ModuleList/>}></Route>
                <Route path={'/download/list'} element={<DownloadRecord/>}></Route>
                <Route path={'/user/pkg/list'} element={<UserPKGList/>}></Route>
            </Route>
        </Routes>
      </BrowserRouter>
  );
}

export default App;