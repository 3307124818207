import React, {useState,useLayoutEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {Table} from 'antd';
import {UserProductApi} from '@api/index'

import HasRole from '@components/security/index'

import './list.less'

export default (props) => {

    let navigate = useNavigate();

    const [list,setList]=useState([])

    const [total,setTotal]=useState(0)
    const [pageSize,setPageSize]=useState(20)
    const [page,setPage]=useState(0)


    useLayoutEffect(() => {
        getUserPKGList()
    }, [page,pageSize])

    const getUserPKGList= async ()=> {
        let params = {}
        params["page"]=page
        params["pageSize"]=pageSize
        let ret = await UserProductApi.list(params);
        if (ret && ret.code === 200) {
            setList(ret.data.content || [])
            setTotal(ret.data.totalElements)
        }
    }


    const columns = [
        {
            title: '用户名称',
            dataIndex: 'userName',
            key: 'userName',
        },
        {
            title: '产品名称',
            dataIndex: 'productName',
            key: 'productName',
        },
        {
            title: '版本',
            dataIndex: 'curVersion',
            key: 'curVersion',
        },
        {
            title: '更新时间',
            dataIndex: 'createTime',
            key: 'createTime',
        },
    ];

    const pagination = {
        onChange: (page, pageSize) => {
            setPageSize(pageSize)
            setPage(page-1)
        },
        pageSize:pageSize,
        total: total
    }

    return (
        <div>
            <Table columns={columns} dataSource={list} pagination={pagination}/>
        </div>
    );
};