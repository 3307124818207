import React from 'react';
import {Form, Input, Modal, message,Select,Switch } from 'antd';
import {ProductApi} from '@api/index'

export default class Add extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            modalVisible: false,
            options:[],
            mainList:[],
        };
        this.formRef = React.createRef();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.modalVisible !== this.state.modalVisible) {
            this.setState({
                modalVisible: nextProps.modalVisible,
            });
            this.getMainList()
        }
    }

    handleOk = async e => {
        let data = await this.formRef.current.validateFields();
        let params={
            type:2,
            ...data
        }
        let ret = await ProductApi.create(params);
        if (ret && ret.code === 200) {
            message.success('操作成功！');
            this.onClose()
            this.setState({
                modalVisible: false,
            });
        } else {
            message.error(ret.message);
        }
    };

    handleCancel = async e => {
        this.onClose()
        this.setState({
            modalVisible: false,
        });
    };

    onClose(){
        this.props.onClose && this.props.onClose()
    }

    getMainList=async () =>{
        let ret = await ProductApi.all({type:1});
        if (ret && ret.code === 200) {
            let arr=ret.data
            let ops = arr.map(((item)=>({ label: item.name, value: item.id })))
            this.setState({
                mainList:ops
            })
        }
    }

    render() {

        return (
            <Modal
                title={"添加组件"}
                open={this.state.modalVisible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                destroyOnClose={true}
            >
                <Form ref={this.formRef} wrapperCol={{span: 14}} labelCol={{span: 6}} autoComplete={"off"}>
                    <Form.Item name="name" label="名称" rules={[{required: true}]}>
                        <Input maxLength={20} allowClear autoComplete={"off"}/>
                    </Form.Item>
                    <Form.Item name="mainId" label="主程序" rules={[{required: true}]}>
                        <Select style={{ width: '100%' }} options={this.state.mainList}/>
                    </Form.Item>

                    <Form.Item name="identifier" label="编号" rules={[{required: true}]}>
                        <Input maxLength={20} allowClear/>
                    </Form.Item>
                    <Form.Item name="introduction" label="介绍" rules={[{required: true}]}>
                        <Input maxLength={20} allowClear/>
                    </Form.Item>
                    <Form.Item name="encrypted" label="加密">
                        <Switch/>
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}
